
import pricing from '../views/register/Pricing.vue'
import registration from '../views/register/Registration.vue'

export default [
    {
      path: "/pricing",
      name: "pricing",
      component:pricing,
      meta: { title: 'Pricing'}
    },
    {
        path: "/registration/:price",
        name: "registration",
        component:registration,
        meta: { title: 'Registration'}
      },
]