<template>
  <section class="wrapper padding-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="content text-center mb-3">
            <h2 class="mb-2">Frequently Asked Questions</h2>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="content">
            <div class="accordion" id="accordionExample">
              <div class="card">
                <div class="card-header p-0 bg-primary" id="headingOne">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-primary text-left w-100"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Veritatis, ea.?
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <div class="content">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Vero nihil neque id, ducimus ea iure fuga vel? Ex
                        aliquid fuga saepe rerum dolorem ea dolores beatae,
                        harum reiciendis ipsam esse.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header p-0 bg-primary" id="headingTwo">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-primary text-left w-100 collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Veritatis, ea.?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <div class="content">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Vero nihil neque id, ducimus ea iure fuga vel? Ex
                        aliquid fuga saepe rerum dolorem ea dolores beatae,
                        harum reiciendis ipsam esse.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header p-0 bg-primary" id="headingThree">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-primary text-left w-100 collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Veritatis, ea.?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <div class="content">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Vero nihil neque id, ducimus ea iure fuga vel? Ex
                        aliquid fuga saepe rerum dolorem ea dolores beatae,
                        harum reiciendis ipsam esse.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>