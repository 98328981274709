<template>
  <header
    section="header-wrapper"
    :style="{backgroundpositon: 'top center',
    backgroundSize: 'cover', backgroundImage: 'url(' + require('@/assets/images/slider-1.jpg') + ')'  }"
  >
  
    <div class="container">
      <div class="row">
        <div class="col-sm-2 col-6">
          <div class="menu-container">
            <div class="logo">
              <img src="@/assets/images/niiyo-logo.png" alt="" />
            </div>

            <div class="dropdown text-center">
              <button
                class="drop-down-icon dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ><font-awesome-icon :icon="['fas', 'caret-down']" />
               
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                <ul class="new-drop-menu">
                                   <!-- <li><router-link class="dropdown-item" :to="{name:'index'}">Home</router-link></li> -->
                                    <li><a href="#" class="dropdown-item" @click="home">Home</a></li>
                                    <!-- <li><a class="dropdown-item" @click="about">About</a> </li> -->
                                    <li class="dropdown-item hvr-menu"><a href="#" class="dropdown-item">Products</a>
                                        <div class="hvr-menu-dropdown">
                                            <ul>
                                                <li><a  href="#" @click="manage">League Management</a></li>
                                                <li><a href="#" @click="scoringapp">Live Scoring App</a></li>
                                               
                                            </ul>
                                        </div>
                                    </li>
                                      <li class="dropdown-item hvr-menu"><a href="#" class="dropdown-item">Resources</a>
                                        <div class="hvr-menu-dropdown">
                                            <ul>
                                                <li><a  href="#"  @click="scoringguide">Scoring Guide</a></li>
                                                <li><a href="#" @click="rank">Rank Calculator</a></li>
                                                 <li><a href="#" @click="faq">FAQS</a></li>
                                               
                                            </ul>
                                        </div>
                                    </li>
                                    <!-- <li><router-link class="dropdown-item" :to="{name:'scoringguide'}">Resources</router-link> </li> -->
                                    <li class="dropdown-item hvr-menu"><a href="#" class="dropdown-item">Company</a>
                                    <div class="hvr-menu-dropdown">
                                            <ul>
                                                <li><a href="#"  @click="about">About</a></li>
                                                <li><a href="#" @click="contact">Contact</a></li>
                                               
                                            </ul>
                                        </div>
                                    
                                    
                                     </li>
                                    <li><a href="#" class="dropdown-item" @click="pricing">Pricing</a> </li>
                                      <!--<li><a href="#" class="dropdown-item" @click="more">More</a> </li>-->
                                </ul>
                            </div>
            </div>
          </div>
        </div>
        <div class="col-sm-10 col-6">
          <div class="top-btn-content text-right" >
             <a href="#" v-show="register" @click="registration" class="white-br-btn hvr-ripple-out">Register</a>
            <!-- <router-link class="white-br-btn hvr-ripple-out" :to="{name:'pricing'}">Register</router-link>  -->
            <!-- <a href="#" class="white-br-btn hvr-ripple-out">Register</a> -->
            <!-- <a
              href="#"
              class="white-br-btn hvr-ripple-out "
              data-toggle="modal"
              data-target="#exampleModal"
              >Login</a> -->
              <!-- <a href="https://stagingurl.tk/scorecard_vue_a/" class="white-br-btn hvr-ripple-out">Login</a> -->
              <a href="/portal/index.html" class="white-br-btn hvr-ripple-out">Login</a>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Sign In</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="login-box">
                <form id="ajax-contact" method="post" action="mailer.php">
                  <div class="row">
                    <div class="field">
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="Username/Email ID"
                        required
                      />
                    </div>

                    <div class="field">
                      <input
                        type="password"
                        id="lname"
                        name="lname"
                        placeholder="Password"
                        required
                      />
                    </div>

                    <button
                      type="button"
                      class="btn blue-br-btn hvr-ripple-out3"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
              <div id="form-messages"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="slider">
        <div class="col-sm-10 offset-md-1">
          <div class="row">
            <div class="col-sm-6">
              <h1><span>Toss the cricket to next level </span> </h1>

              <a href="#"  @click="pricing" class="white-br-btn hvr-ripple-out">GET STARTED</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import $ from "jquery";

    export default{
    name:"Header",
    data(){
       return{
         register:true
       }
      // register:true

    },
    created(){
    
    },
    methods:{
      registration(){
        this.$router.push({path:'/pricing'})
        this.register=false
      },
      home(){
 this.$router.push({path:'/'})
         this.register=true
      },
      manage(){
        this.$router.push({path:'/products/league'})
         this.register=true

      },
      scoringapp(){
        this.$router.push({path:'/products/livescoring'})
         this.register=true

      },
      scoringguide(){
        this.$router.push({path:'/resource/scoring'})
         this.register=true

      },
      rank(){
        this.$router.push({path:'/resource/rankcalculator'})
         this.register=true

      },
      faq(){
        this.$router.push({path:'/resource/faq'})
         this.register=true

      },
      about(){
        this.$router.push({path:'/company/about'})
         this.register=true

      },
contact(){
  this.$router.push({path:'/company/contact'})
         this.register=true

},

pricing(){
  this.$router.push({path:'/pricing'})
         this.register=false

},
// more(){
//   this.$router.push({path:'/pricing'})
//         this.register=false

// }
      // login(){
      //    this.$router.push({path:'https://stagingurl.tk/scorecard_vue_a/'})
      // }
    }
}
  $(document).ready(function() {
            $('.burger').click(function() {
                $(this).toggleClass('open');
            });
        });


        $(document).ready(function() {
            $('.burger').click(function() {
                $('.main-nav').toggle('');
            });
        });

 $(document).ready(function() {
            $(window).scroll(function() {
                if ($(this).scrollTop() > 400) {
                    $('.scrollup').fadeIn();
                } else {
                    $('.scrollup').fadeOut();
                }
            });
            $('.scrollup').click(function() {
                $("html, body").animate({
                    scrollTop: 0
                }, 600);
                return false;
            });
        });
          
         
</script>

<style>
    .dropdown-item:hover .hvr-menu-dropdown{
        display: block !important;
    }
</style>