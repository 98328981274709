
import Api from "./common";
import request from '@/utilis/request'

export function registrationform (form)  {
    return Api().post('club-admin-create', form);
  
  }

  export function contactform (form)  {
    return Api().post('club/contact_us', form);
  
  }

  
  export function getCustomer () {
  
    return request({
      url: 'payment',
      method: 'get',
      // user: user
    })
  }



export function getList () {
  
    return request({
      url: '/feature-price-list',
    
      method: 'get',
      // user: user
    })
  }
  export function getField () {
  
    return request({
      url: '/feature/list',
    
      method: 'get',
      // user: user
    })
  }

  export function getStateList(params) {
    return request({
      url: '/state/lists',
      method: 'get',
      params: params
    })
  }
  
  export function getCityList(params) {
    return request({
      url: '/city/lists',
      method: 'get',
      params: params
    })
  }
  
  export function getCityListByStateId(state_id) {
    return request({
      url: '/city/lists/'+state_id,
      method: 'get'
    })
  }
  export function getCountryList() {
    return request({
      url: '/country/lists',
      method: 'get',
     
     
    })
  }
  export function getstatelistbyCountryId(country_id) {
    return request({
      url: '/country-by-state/'+country_id,
      method: 'get'
    })
  }
  