<template>
  <section class="wrapper padding-100 register-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-8">
          <div class="content white-box-2" v-show="paymentsuccess">
            <h3> Your Subscription was successfully created!!!</h3>
            <h3>Kindly find the login credentials in your registered mail</h3>
           <a href="https://stagingurl.tk/scorecard_vue_a/"> <h5 style="font-size:15px">Click here to login</h5></a>
          
            </div>
          <div class="content white-box-2" v-show="paymentform">
            <h4 class="text-center">PACKAGE - COMMUNITY (${{this.$route.params.price}}- per team)</h4>

            <div class="contact-form">
               <!-- <form action="#" class="form " @submit.prevent="contact" > -->
              <!-- <form id="ajax-contact" method="post"  @submit.prevent="contact"> -->
                <div class="row">
                  <div class="col-sm-12">
                    <div class="field">
                      <label>Club Name<Span>*</Span></label>
                      <input
                        type="text"
                        v-model="form.name"
                        name="fname"
                        placeholder="Club Name"
                        required
                      />
                      <span v-if="allerros.name" class="form-err">{{ allerros.name[0] }}</span>
                    
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>First Name<Span>*</Span></label>
                      <input
                        type="text"
                        v-model="form.first_name"
                        name="fname"
                        placeholder="First Name"
                        required
                      />
                       <span v-if="allerros.first_name" class="form-err">{{ allerros.first_name[0] }}</span>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Last Name<Span>*</Span></label>
                      <input
                        type="text"
                        v-model="form.last_name"
                        name="lname"
                        placeholder="Last Name"
                        required
                      />
                       <span v-if="allerros.last_name" class="form-err">{{ allerros.last_name[0] }}</span>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Email<Span>*</Span></label>
                      <input
                        type="email"
                        v-model="form.email"
                        name="email"
                        placeholder="Email"
                        required
                      />
                      <span v-if="allerros.email" class="form-err">{{ allerros.email[0] }}</span>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Phone Number<Span>*</Span></label>
                      <input
                        type="tel"
                        v-model="form.phone"
                        name="phone"
                        placeholder="Phone Number"
                        required
                      />
                      <span v-if="allerros.phone" class="form-err">The phone field is required</span>
                    </div>
                  </div>

                  <div class="col-sm-12">
                    <div class="field">
                      <label>Address<Span>*</Span></label>
                      <textarea
                        name="message"
                        v-model="form.address"
                        placeholder="Address"
                        rows="3"
                        cols="50"
                        required
                      ></textarea>
                      <span v-if="allerros.address" class="form-err">{{ allerros.address[0] }}</span>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Country</label>
                       <select name="state" v-model="form.country" @change="getstatelistbyCountryId">
                      
                        <option v-bind:value="null" disabled>Select Country</option>
                            <option v-for="country in country_list" v-bind:value="country.country_id" v-bind:key="country.country_id">
                              {{country.name}}
                            </option>
                      </select>
                       <span v-if="allerros.country" class="form-err">{{ allerros.country[0] }}</span>
                      <!-- <select name="country" v-model="form.country">
                        <option>select Country</option>
                      </select> -->
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Province</label>
                      <select name="state" v-model="form.state" @change="getCityListByStateId">
                      
                        <option v-bind:value="null" disabled>Select Province</option>
                            <option v-for="state in state_list" v-bind:value="state.state_id" v-bind:key="state.state_id">
                              {{state.state_name}}
                            </option>
                      </select>
                       <span v-if="allerros.state" class="form-err">The Province field is required</span>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>City / Town </label>
                      <!-- <input type="text" name="city" v-model="form.city" placeholder="" required /> -->
                       <select class="select2" v-model="form.city">
                            <option v-bind:value="null" disabled>Select City</option>
                            <option v-for="city in city_list" v-bind:value="city.city_id" v-bind:key="city.city_id">
                              {{city.city_name}}
                            </option>
                          </select>
                           <span v-if="allerros.city" class="form-err">{{ allerros.city[0] }}</span>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Postal code </label>
                      <input type="text" v-model="form.zipcode" name="zip" placeholder="" required />
                       <span v-if="allerros.zipcode" class="form-err">The Postal code field is required</span>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Approx. No. of Teams<Span>*</Span></label>
                      <input
                        onkeyup="changeAmountAndHeading()"
                       :value="form.no_teams"
                           name="no_teams" 
                          id="no_teams"
                        @change="updateRate"
                        type="number"
                        min="0"
                        class="form-control rounded-0"
                        required=""
                      />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group field">
                      <label>Yearly Package Cost</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input
                          name="total_amount"
                         
                        
                         v-model="form.total_amount"
                          id="total_amount"
                          type="text"
                          class="form-control rounded-0"
                          placeholder=""
                          aria-label="package-total"
                          aria-describedby="package-currency"
                          readonly=""
                        />
                      </div>
                    </div>
                  </div>
                   <div class="col-sm-8" style="color:red">
                     {{stripeValidationError}}
                     <!-- <div class="field mb-1">
                        <label>Enter Card Details</label>
                         <input type="text" v-model="form.zip" name="zip" placeholder="" required />
                     </div> -->
                  
                    </div>
                  
                    <div class="col-sm-8">
                       <div class="field mb-1">
                        <label>Enter Card Details</label>
                        </div>
                      <div class="form-group field">
                        <div ref="card" class="input-style"></div>
                      </div>
                    </div>




                  <div class="col-lg-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        name="accept"
                        v-model="form.accept"
                      />
                     
                      <label class="form-check-label" for="flexCheckDefault">
                        Accept our <a href="" class="terms-anchor">Terms & Conditions</a> and
                        <a href="" class="terms-anchor">Privacy Policy</a>
                      </label><br>
                       <span v-if="allerros.accept" class="form-err">Please accept our terms</span> 
                      
                    </div>
                     <!-- <span v-if="allerros.accept" class="form-err">{{ allerros.accept[0] }}</span>  -->
                  </div>

                  <div class="col-sm-12">
                    <div class="field">
                      <button type="submit" class="red-br-btn hvr-ripple-out2" @click="contact">
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              <!-- </form> -->
               <p id="text" style="color:green; margin-left:400px;"></p>
               
              <div id="form-messages"> <p id="text" style="color:green; margin-left:400px;"></p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

     
 
</template>
<script>

import {registrationform} from '@/api/registration'
import { getCityList, getStateList,getCityListByStateId,getCountryList,getstatelistbyCountryId } from '@/api/registration'
// let style = {
//   base: {
//     border: '1px solid #D8D8D8',
//     borderRadius: '4px',
//     color: "#000",
//   },
//  }
const stripe =window.Stripe(`pk_test_51IwMJiEb3MV4Rb0896bfhMNDmDoM2BK7WuKpQgSykdFiXcbip7WVlmNUHWFVYaAKEwA9KnoYBr3KMb0RetHOI1Y0002rxCmN2f`);
   let elements = stripe.elements();
  let  card = undefined;

export default {
  name: "registration",
 
  data(){
     return{
      loading: false,
      country_list:[],
    city_list: [],
      state_list: [],
     stripeValidationError:'',
      allerros:[],
      paymentform:true,
      paymentsuccess:false,
       success : false,  
      form:{
         name:"",
         first_name:"",
         last_name:"",
         email:"",
         phone:"",
         address:"",
         no_teams:'',
         total_amount:'',
         token:'',
         card_id:'',
         card_brand:'',
         last_four:'',
         city:null,
         state:null,
         country:null,
         zipcode:'',
         accept: false,
         role_id:'2'

      }
     }
  },
   beforeUnmount() {
        card.destroy(this.$refs.card);
},
   mounted: function () {
    
    card = elements.create('card',{hidePostalCode: true});
    card.mount(this.$refs.card);
  },
  created(){
  this.getStateList();
    this.getCityList();
    this.getCountryList();
    
  },
  methods:{
 
    getCityList(){
      this.loading = true
      getCityList().then(response => {
        this.city_list = response.data.data
        this.loading = false
      })
    },
    getStateList(){
      this.loading = true
      getStateList().then(response => {
        this.state_list = response.data.data
        this.loading = false
      })
    },
    getCountryList(){
      this.loading = true
      getCountryList().then(response=>{
      
        this.country_list = response.data.data
       
      })

    },
    getstatelistbyCountryId(){
      this.loading = true
      getstatelistbyCountryId(this.form.country).then(response=>{
        this.state_list= response.data.data
        
      })

    },
   getCityListByStateId(){
      this.loading = true
      getCityListByStateId(this.form.state).then(response => {
        this.city_list = response.data.data
       
        this.loading = false
      })
    },
    updateRate(event) {
        this.form.no_teams = event.target.value;
         this.form.total_amount = this.$route.params.price * this.form.no_teams
    },
     contact(){


    // let me=this;
    //  let self = this;
    stripe.createToken(card).then(result => {
      // console.log(result)
          if (result.error) {
              this.stripeValidationError=result.error.message
              this.hasCardErrors = true;
              // self.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
              // return;
          }else if (result.token) {
                  this.form.token=result.token.id
                  this.form.card_id=result.token.card.id
                  this.form.card_brand = result.token.card.brand
                  this.form.last_four = result.token.card.last4
                  registrationform(this.form)
                    .then(( response ) => { 
                      if(response.data.message=='success'){
                        this.paymentsuccess=true
                        this.paymentform=false
                        //  var attr = document.getElementById("text");
                        // attr.innerHTML = 'Successfully submitted'; 
                        // console.log(response.data,'message');
                        // this.$router.push({path:'/'})
                      }
                  
                    }).catch((error) => {
                      console.log( error.response.data)
                       this.allerros = error.response.data.error;
                        this.success = false;
                       
          this.loading=false;
      })
          }

//         console.log(card,'card');
//         console.log(result,'result')
//       // Access the token with result.token
//       // this.form.token=result.token;
//       // console.log(result.token);
//       me.form.card=result.token.card;
//       me.form.token=result.token.id;
//       // me.form.card=result.token.card;
//  registrationform(me.form)
//                     .then(( response ) => { 
//                   console.log(response.data,'message');
//                   // this.$router.push('/');
//                         var attr = document.getElementById("text");
//                         attr.innerHTML = response.data.message+  +'submitted';  
//         //  this.form.club_name="",
//         //  this.form.first_name="",
//         // this.form.last_name="",
//         // this.form.mail="",
//         // this.form.phone="",
//         // this.form.addrs="",
        
//                       //  contactform(this.form).reset();
                       
//                         })

       
    })
    
  



       

      

     }
     
  }
};
</script>
