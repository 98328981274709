import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Stripetest from '../views/Stripetest.vue'
import larastripe from '../views/larastripe.vue'
import testlara from '../views/testlara.vue'
import cardstripe from '../views/cardstripe.vue'
import About_us from '../views/company/About_us.vue'

import Contact from '../views/company/Contact.vue'
import productsRoutes from './products'
import registerRoutes from './register'
import resourcesRoutes from './resources'
const routerMap = [

  {
   
    path: '/',
    name: 'index',
    component: Index
  },
  {
   
    path: '/company/about',
    name: 'about',
    component: About_us
  },
  

  {
   
    path: '/company/contact',
    name: 'contact',
    component: Contact
  },
  {
   
    path: '/stripe',
    name: 'stripe',
    component: Stripetest
  },
  {
   
    path: '/teststripe',
    name: 'larastripe',
    component: larastripe
  },
  {
   
    path: '/stripeinte',
    name: 'testlara',
    component: testlara
  },
  {
   
    path: '/stripcard',
    name: 'cardstripe',
    component: cardstripe
  },
]
const routes = routerMap.concat(
  productsRoutes,
  registerRoutes,
  resourcesRoutes

)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
