<template>
  <section class="wrapper padding-100">
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-lg-10">
          <div class="content text-center mb-5">
            <h2>League Management</h2>
            <p>
              We provide an all-in-one software platform for cricket administrators with all the tools needed to keep your leagues running smoothly. With a customized league website, tournament setup, live scoring, automatic points table and stats, Circforever league management solution is a perfect choice.
            </p>
          </div>
        </div>
        <ul class="nav nav-tabs mb-5 new-links-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="home" aria-selected="true">Dashboard</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="profile" aria-selected="false">Themes</a>
            </li>

            <li class="nav-item">
                <a class="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="profile" aria-selected="false">Field Management</a>
            </li>

            <li class="nav-item">
                <a class="nav-link" id="four-tab" data-toggle="tab" href="#four" role="tab" aria-controls="profile" aria-selected="false">Match Center</a>
            </li>

            <li class="nav-item">
                <a class="nav-link" id="five-tab" data-toggle="tab" href="#five" role="tab" aria-controls="profile" aria-selected="false">Admin</a>
            </li>

            <li class="nav-item">
                <a class="nav-link" id="six-tab" data-toggle="tab" href="#six" role="tab" aria-controls="profile" aria-selected="false">Customization</a>
            </li>

        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
            <div class="row mb-4 p-3">
              <div class="col-lg-6">
                <div class="content">
                  <h3>Dashboard</h3>
                  <p>
                    The dashboard is a highly intuitive and easily navigable zone for league admin and cricket managers where you can manage all aspects of your league with ease. With our user-friendly dashboard, you can take full control of player registration, matches, and schedules, and much more. 
                  </p>
                  <p>
                    Focus on what really matters for your league, rather than spending endless hours tussling with piles of paper.  
                  </p>
                </div>
              </div>
              <div class="col-lg-6 align-self-enter">
                <div class="content">
                  <img src="@/assets/images/slider-bg-3.jpg" alt="" class="img-responsive">
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
              <div class="row mb-4 p-3">
                  <div class="col-lg-6">
                    <div class="content">
                      <h3>Themes</h3>
                      <p>
                        With options like choosing colours, styles, as well as providing your own logo, you get to customize your league exactly the way you want it. All you need to do is select the right theme of your choice and apply, and that's it. 
                      </p>
                      <p>
                      Now, Cricforever is just the way you like it. It’s your way of Cricket. 
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6 align-self-enter">
                    <div class="content">
                      <img src="@/assets/images/slider-3.jpg" alt="" class="img-responsive" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">
                <div class="row mb-4 p-3">
                  <div class="col-lg-6">
                    <div class="content">
                      <h3>Field Management</h3>
                      <p>
                        Our cricket application will reinvent the way you enjoy the game by tracking every run, ball, and managing your matches & leagues like a pro. Embrace the technology to ease the hassle of field management with an automated system and easily comprehensible stats. 
                      </p>
                      <p>
                      Managing your cricket team has never been easier before. Sign up now!
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6 align-self-enter">
                    <div class="content">
                      <img src="@/assets/images/slider-4.jpg" alt="" class="img-responsive" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="four" role="tabpanel" aria-labelledby="four-tab">
                <div class="row mb-4 p-3">
                  <div class="col-lg-6">
                    <div class="content">
                      <h3>Match Center</h3>
                      <p>
                        Cricforever makes live scoring updates on the go and enables you to capture every aspect of the game right from the field. Our simple user interface and stunning design make it easy to keep track of every detail.  
                      </p>
                      <p>
                      Track performance and record scores like a professional match. 
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6 align-self-enter">
                    <div class="content">
                      <img src="@/assets/images/slider-5.jpg" alt="" class="img-responsive" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="five" role="tabpanel" aria-labelledby="five-tab">
                <div class="row mb-4 p-3">
                  <div class="col-lg-6">
                    <div class="content">
                      <h3>Admin</h3>
                      <p>
                        Bring your club to the next level by increasing your efficiency. Cricforever helps your team stay in touch no matter where they are. Manage league and tournament schedules online, and track match results. 
                      </p>
                      <p>
                      Redefining Cricket!  
                      </p>
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-enter">
                    <div class="content">
                      <img src="@/assets/images/slider-6.jpg" alt="" class="img-responsive" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="six" role="tabpanel" aria-labelledby="six-tab">
                <div class="row mb-4 p-3">
                  <div class="col-lg-6">
                    <div class="content">
                      <h3>Customization</h3>
                      <p>
                        The sky is the limit with Cricforever. From colours, points tables styles, user interfaces to dedicated websites that align with your requirements and preferences, we offer a variety of customization options.  
                      </p>
                      <p>
                        Making cricket more fun. 
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6 align-self-enter">
                    <div class="content">
                      <img src="@/assets/images/slider-7.jpg" alt="" class="img-responsive" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  </section>
</template>