<template>
  <section class="wrapper padding-100 pricing-bg">
    <div class="container">
      <div class="row">
        <div class="content text-center">
          <h2>League Management Pricing</h2>
          <h5>Choose A Product and the plan</h5>
        </div>
        <div class="pricing-container">
          <div class="row">
            <div class="col-sm-4">
              <div class="pricing">
                <div class="pricing-content">
                  <h3>Basic</h3>
                  <h2>$20</h2>
                  <p>Community</p>
                  <a @click="registration(20)" class="blue-br-btn hvr-ripple-out3 started">Get Started</a>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="pricing">
                <div class="pricing-content">
                  <h3>Essentials</h3>
                  <h2>$30</h2>
                  <p>Team/Month</p>
                  <a @click="registration(30)" class="blue-br-btn hvr-ripple-out3 started">Get Started</a>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="pricing">
                <div class="pricing-content premium-box">
                  <h3>Premium</h3>
                  <h2>$40</h2>
                  <p>Team/Month</p>
                   <a @click="registration(40)" class="blue-br-btn hvr-ripple-out3 started">Get Started</a>
              <!-- <router-link :to="{name:'registration'}" class="red-br-btn hvr-ripple-out2"> Get Started </router-link>   -->
              
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper" >
         <div v-for="(feature,index) in features" v-bind:key="index">
           <div v-if="((feature.futurelist).length!==0)">
          <div class="header-bg" >
            <h2 >{{feature.field_name}}</h2>
          </div>

                              <table class="table pricing-table"  >
                                <thead class="sub-table-header">
                                  <tr>
                                    <th scope="col">#</th>
                                    <td scope="col">Basic</td>
                                    <td scope="col">Essentials</td>
                                    <td scope="col">Premium</td>
                                   
                                     
                                  </tr>
                                </thead>
                                <tr  v-for="(fieldList,index) in feature.futurelist"  :key="index">
                                  <td class="col-md-4">{{fieldList.feature_content}}</td>

                                  <td v-if="(fieldList.basic_id==1)"><font-awesome-icon icon="check" /></td>
                                    <td v-else> <font-awesome-icon icon="times"  style="color:red"/></td>
                                    <td v-if="(fieldList.premium_id==1)"> <font-awesome-icon icon="check" /> </td>
                                    <td v-else> <font-awesome-icon icon="times"  style="color:red"/> </td>
                                    <td v-if="(fieldList.essential_id==1)" > <font-awesome-icon icon="check" /></td>
                                    <td v-else ><font-awesome-icon icon="times"  style="color:red"/> </td> 
                                   
                                </tr>
                                    </table>
          <!-- <table class="table pricing-table" v-if="(feature.feature_title==field.id)">
          
            <thead class="sub-table-header">
              <tr>
                <th scope="col">#</th>
                <td scope="col">Bssic</td>
                <td scope="col">Essentials</td>

                <td scope="col">Premium</td>
              </tr>
            </thead>
           
            <tbody v-for="(feature,index) in features" v-bind:key="index" >
                        <tr v-if="(feature.feature_title==field.id)">
                <th scope="row" >{{feature.feature_content}}</th>
               

               <td v-if="(feature.basic_id==1)"> <font-awesome-icon icon="check" /></td>
                  <td v-else> <font-awesome-icon icon="times"  style="color:red"/></td>
                <td v-if="(feature.premium_id==1)"> <font-awesome-icon icon="check" /></td>
                 <td v-else> <font-awesome-icon icon="times"  style="color:red"/></td>
                <td v-if="(feature.essential_id==1)" ><font-awesome-icon icon="check" /></td>
                <td v-else> <font-awesome-icon icon="times"  style="color:red"/></td> 
              </tr>
            
                          
            </tbody>
          </table> -->
          </div>
          </div>
        
          <!-- <table class="table pricing-table"> -->
        
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {getList} from '@/api/registration'
import {getField} from '@/api/registration'

// import { defineComponent } from '@vue/composition-api'

export default {
 name: "pricing",

  data(){
     return{
      loading: false,
      features:'',
      fields:''
     }
   },
   created () {
     this.fetchData(1)
    
     },
     methods:{
       registration(price){
         this.$router.push({path:'/registration/'+price})

       },
        fetchData (){
           
            this.loading = true
            
            getList().then(response => {
            this.features = response.data.data
            
            console.log(response.data.data,'team');
            this.loading = false
            }),
            getField().then(response => {
            this.fields = response.data.data
            
            console.log(response.data.data,'field');
            this.loading = false
            })
            
       
     }
     }
}
 
</script>
