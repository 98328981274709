<template>
    <div>
         <loading :active='loading' :is-full-page="true" loader='bars' />

  <section class="wrapper padding-100 light-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="content text-left">
            <h3>Cricforever</h3>
            <h6>Address:</h6>
            <p>
              No.7, New Colonoy,<br />
              Steer, Country
            </p>
            <h6>Email:</h6>
            <p>infor@cricforever.ca</p>
            <h6>Phone:</h6>
            <p>+123456789</p>
          </div>
        </div>

        <div class="col-lg-5 align-self-enter">
          <div class="content p-4 bg-white text-left">
               <div v-show="formresponse">
            <h3>Your message has been sent !!</h3>
          </div>
            <div v-show="formdata">
            <form @submit.prevent="contact">
              <div class="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input type="text" name="user_name"   v-model="form.name" class="form-control" />
                 <span v-if="allerros.name" class="form-err">{{ allerros.name[0] }}</span>
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input type="email" id="mail" name="usermail" v-model="form.email" class="form-control" />
                 <span v-if="allerros.email" class="form-err">{{ allerros.name[0] }}</span>
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Purpose</label>
                <select class="form-control"  name="option" v-model="form.purpose">
                  <option>Option 1</option>
                  <option>Option 2</option>
                </select>
                 <span v-if="allerros.purpose" class="form-err">{{ allerros.purpose[0] }}</span>
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Message</label>
                <textarea
                  class="form-control" name="message" v-model="form.message"
                  id="exampleFormControlTextarea1"
                  rows="1"
                ></textarea>
                 <span v-if="allerros.message" class="form-err">{{ allerros.message[0] }}</span>
              </div>
              <button type="submit" class="red-br-btn hvr-ripple-out2 mt-3">
                Submit
              </button>
            </form>
            </div>
             <p id="text" style="color:green; margin-left:100px;"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
</template>
<script>

import {contactform} from '@/api/registration'
export default {
  name: "contact",
 
  data(){
     return{
      loading: false,
   allerros:'',
      formresponse:false,
      formdata:true,
      form:{
        
         name:"",
         email:"",
        purpose:"",
         message:"",
        
      }
     }
  },
  created(){
   // this.fetchData(1);
  },
  methods:{
   
   
     contact(){
          this.loading=true;
         console.log(this.form,'data');
        contactform(this.form)
                    .then( response  => { 
                  console.log(response.error,'message');
                   this.formresponse=true;
                  this.formdata=false;
                        // var attr = document.getElementById("text");
                        // attr.innerHTML = 'Successfully submitted';  
         this.form.club_name="",
         this.form.first_name="",
        this.form.last_name="",
        this.form.mail="",
        this.form.phone="",
        this.form.addrs=""
        
                        this.loading=false;
                       
                        }).catch((error) => {
                         
                       this.allerros = error.response.data.error;
                        this.success = false;
                       
          this.loading=false;
      })


      

     }
  }
};
</script>
