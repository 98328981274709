<template>
 <section class="wrapper padding-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="content text-center mb-5">
            <h2>Live-Scoring App </h2>
            <p>
             With Cricforever, you can track every ball, count every run and see match information from your mobile device as it happens. Unleash the power of statistics and real-time data to local or world-renowned competitions. 
            </p>
          </div>
        </div>
 <ul class="nav nav-tabs mb-5 new-links-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="home" aria-selected="true">Free Download</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="profile" aria-selected="false">Team Schedule</a>
            </li>

            <li class="nav-item">
                <a class="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="profile" aria-selected="false">Score Updates</a>
            </li>

            <li class="nav-item">
                <a class="nav-link" id="four-tab" data-toggle="tab" href="#four" role="tab" aria-controls="profile" aria-selected="false">Points Table</a>
            </li>

            <li class="nav-item">
                <a class="nav-link" id="five-tab" data-toggle="tab" href="#five" role="tab" aria-controls="profile" aria-selected="false">Statistics </a>
            </li>

            <li class="nav-item">
                <a class="nav-link" id="six-tab" data-toggle="tab" href="#six" role="tab" aria-controls="profile" aria-selected="false">Gallery  </a>
            </li>

        </ul>
          <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
            <div class="row mb-4 p-3">
              <div class="col-lg-6">
                <div class="content">
            <h3>Free Download </h3>
            <p>
            Get ball-by-ball scoring and commentary for your local matches, available for Android and iOS devices. It's free and easy to use. We're dedicated to creating the best online cricket environments for cricket lovers.  
            </p>
            <p>
             The life app!  
            </p>
          </div>
        </div>

         <div class="col-lg-6 align-self-enter">
                <div class="content">
            <img src="@/assets/images/live1.jpg" alt="" class="img-responsive" />
           </div>
              </div>
            </div>
          </div>

        <div class="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
              <div class="row mb-4 p-3">
                  <div class="col-lg-6">
                    <div class="content">
            <h3>Team Schedule </h3>
            <p>
             A whole new world is waiting for you. Using the app, you can match schedules for teams with domestic and international fixtures in all formats. Save time on administrative tasks and focus more on the club's growth.  
            </p>
            <p>
           The best in the field.  
            </p>
          </div>
        </div>

        <div class="col-lg-6 align-self-enter">
                    <div class="content">
            <img src="@/assets/images/live2.jpg" alt="" class="img-responsive" />
          </div>
                  </div>
                </div>
              </div>

        <div class="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">
                <div class="row mb-4 p-3">
                  <div class="col-lg-6">
                    <div class="content">
            <h3>Score Updates </h3>
            <p>
             Get inspired with the captivating app where you can enjoy cricket with a variety of features such as team schedule and live score updates. Give your game a chance of accepting new opportunities in the digital world. 
            </p>
            <p>
            Always think forward.
            </p>
          </div>
        </div>

        <div class="col-lg-6 align-self-enter">
                    <div class="content">
            <img src="@/assets/images/live3.jpg" alt="" class="img-responsive" />
          </div>
                  </div>
                </div>
              </div>
        <div class="tab-pane fade" id="four" role="tabpanel" aria-labelledby="four-tab">
                <div class="row mb-4 p-3">
                  <div class="col-lg-6">
                    <div class="content">
            <h3>Points Table </h3>
            <p>
             Followers and fans can get the updates of the points table immediately after each match so they can keep track of their favourite team's performance and standings. Your favourite team’s point table at your fingertips.  
            </p>
            <p>
            An elegant app in your pocket. 
            </p>
          </div>
        </div>

         <div class="col-lg-6 align-self-enter">
                    <div class="content">
            <img src="@/assets/images/live4.jpg" alt="" class="img-responsive" />
          </div>
                  </div>
                </div>
              </div>
        <div class="tab-pane fade" id="five" role="tabpanel" aria-labelledby="five-tab">
                <div class="row mb-4 p-3">
                  <div class="col-lg-6">
                    <div class="content">
            <h3>Statistics </h3>
            <p>
            We offer all the amazing solutions to manage cricket stats and rankings of every player designed specifically for cricket fans. Browse stats team-wide or player-by-player that you can access any time from a single all-in-one platform.   
            </p>
            <p>
            Technology has made it real that our enthusiasm never drops.   
            </p>
          </div>
        </div>

        <div class="col-lg-6 align-self-enter">
                    <div class="content">
            <img src="@/assets/images/live5.jpg" alt="" class="img-responsive" />
          </div>
                  </div>
                </div>
              </div>

        <div class="tab-pane fade" id="six" role="tabpanel" aria-labelledby="six-tab">
                <div class="row mb-4 p-3">
                  <div class="col-lg-6">
                    <div class="content">
            <h3>Gallery </h3>
            <p>
              Cricket is not just a game, it’s an array of emotions. Your tournaments end but your memories last forever. The gallery exhibits a repository of all the news surrounding the teams throughout the season, as well as team images, individual images, shot images, video highlights, and more.  
            </p>
            <p>
             Cricket soul! 
            </p>
          </div>
        </div>

         <div class="col-lg-6 align-self-enter">
                    <div class="content">
            <img src="@/assets/images/live6.jpg" alt="" class="img-responsive" />
          </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  </section>
</template>