<template>
  <div id="nav">
 
    <Header/> 
   <loading :active='loading' :is-full-page="true" :loader='loader' />
    <router-view>
    </router-view>
    <Footer/>
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  </div>
  <!-- <router-view/> -->
</template>

<script>


import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
export default{
  name:'app',
  components:{
    Header, Footer
  },
  data(){
    return {
      base_url:'',
      loader: 'bars',
      // loading: false,
    }
  },
  
  created () {
     this.base_url=process.env.BASE_URL;
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 0px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

/* #nav a.router-link-exact-active { */
  /* color: #42b983; */
/* } */
</style>
