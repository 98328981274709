<template>
  <section class="wrapper padding-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="content text-center mb-3">
            <h2 class="mb-2">Rank Calculator</h2>
            <p>Player Ranking Calculation:</p>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="content">
            <div class="accordion" id="accordionExample">
              <div class="card">
                <div class="card-header p-0 bg-primary" id="headingOne">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-primary text-left w-100"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Batting Points
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-6">
                        <table class="table table-striped ranks-table">
                          <tbody>
                            <tr>
                              <td>Run scored</td>
                              <td>1 point</td>
                            </tr>

                            <tr>
                              <td>Six runs (off one ball) bonus</td>
                              <td>2 points</td>
                            </tr>

                            <tr>
                              <td>Four runs (off one ball) bonus</td>
                              <td>1 point</td>
                            </tr>
                            <tr>
                              <td>Dismissed for duck</td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Strike Rate Between 0.00 and 49.99 runs per 100
                                balls:
                              </td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 50.00 and 74.99 runs per 100 balls
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 75.00 and 99.99 runs per 100 balls:
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 100.00 and 124.99 runs per 100 balls:
                              </td>
                              <td>10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 125.00 and 149.99 runs per 100 balls:
                              </td>
                              <td>20 points</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="col-lg-6">
                        <table class="table table-striped ranks-table">
                          <tbody>
                            <tr>
                              <td>Run scored</td>
                              <td>1 point</td>
                            </tr>

                            <tr>
                              <td>Six runs (off one ball) bonus</td>
                              <td>2 points</td>
                            </tr>

                            <tr>
                              <td>Four runs (off one ball) bonus</td>
                              <td>1 point</td>
                            </tr>
                            <tr>
                              <td>Dismissed for duck</td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Strike Rate Between 0.00 and 49.99 runs per 100
                                balls:
                              </td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 50.00 and 74.99 runs per 100 balls
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 75.00 and 99.99 runs per 100 balls:
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 100.00 and 124.99 runs per 100 balls:
                              </td>
                              <td>10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 125.00 and 149.99 runs per 100 balls:
                              </td>
                              <td>20 points</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header p-0 bg-primary" id="headingTwo">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-primary text-left w-100 collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Bowling Points
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-6">
                        <table class="table table-striped ranks-table">
                          <tbody>
                            <tr>
                              <td>Run scored</td>
                              <td>1 point</td>
                            </tr>

                            <tr>
                              <td>Six runs (off one ball) bonus</td>
                              <td>2 points</td>
                            </tr>

                            <tr>
                              <td>Four runs (off one ball) bonus</td>
                              <td>1 point</td>
                            </tr>
                            <tr>
                              <td>Dismissed for duck</td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Strike Rate Between 0.00 and 49.99 runs per 100
                                balls:
                              </td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 50.00 and 74.99 runs per 100 balls
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 75.00 and 99.99 runs per 100 balls:
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 100.00 and 124.99 runs per 100 balls:
                              </td>
                              <td>10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 125.00 and 149.99 runs per 100 balls:
                              </td>
                              <td>20 points</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="col-lg-6">
                        <table class="table table-striped ranks-table">
                          <tbody>
                            <tr>
                              <td>Run scored</td>
                              <td>1 point</td>
                            </tr>

                            <tr>
                              <td>Six runs (off one ball) bonus</td>
                              <td>2 points</td>
                            </tr>

                            <tr>
                              <td>Four runs (off one ball) bonus</td>
                              <td>1 point</td>
                            </tr>
                            <tr>
                              <td>Dismissed for duck</td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Strike Rate Between 0.00 and 49.99 runs per 100
                                balls:
                              </td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 50.00 and 74.99 runs per 100 balls
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 75.00 and 99.99 runs per 100 balls:
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 100.00 and 124.99 runs per 100 balls:
                              </td>
                              <td>10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 125.00 and 149.99 runs per 100 balls:
                              </td>
                              <td>20 points</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header p-0 bg-primary" id="headingThree">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-primary text-left w-100 collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Fielding/Other Points
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-6">
                        <table class="table table-striped ranks-table">
                          <tbody>
                            <tr>
                              <td>Run scored</td>
                              <td>1 point</td>
                            </tr>

                            <tr>
                              <td>Six runs (off one ball) bonus</td>
                              <td>2 points</td>
                            </tr>

                            <tr>
                              <td>Four runs (off one ball) bonus</td>
                              <td>1 point</td>
                            </tr>
                            <tr>
                              <td>Dismissed for duck</td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Strike Rate Between 0.00 and 49.99 runs per 100
                                balls:
                              </td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 50.00 and 74.99 runs per 100 balls
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 75.00 and 99.99 runs per 100 balls:
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 100.00 and 124.99 runs per 100 balls:
                              </td>
                              <td>10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 125.00 and 149.99 runs per 100 balls:
                              </td>
                              <td>20 points</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="col-lg-6">
                        <table class="table table-striped ranks-table">
                          <tbody>
                            <tr>
                              <td>Run scored</td>
                              <td>1 point</td>
                            </tr>

                            <tr>
                              <td>Six runs (off one ball) bonus</td>
                              <td>2 points</td>
                            </tr>

                            <tr>
                              <td>Four runs (off one ball) bonus</td>
                              <td>1 point</td>
                            </tr>
                            <tr>
                              <td>Dismissed for duck</td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Strike Rate Between 0.00 and 49.99 runs per 100
                                balls:
                              </td>
                              <td>-10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 50.00 and 74.99 runs per 100 balls
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 75.00 and 99.99 runs per 100 balls:
                              </td>
                              <td>0 point</td>
                            </tr>
                            <tr>
                              <td>
                                Between 100.00 and 124.99 runs per 100 balls:
                              </td>
                              <td>10 points</td>
                            </tr>
                            <tr>
                              <td>
                                Between 125.00 and 149.99 runs per 100 balls:
                              </td>
                              <td>20 points</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  
</template>