<template>

<div class="col-md-6">
   <div class="row">
                  <div class="col-sm-12">
                    <div class="field">
                      <label>Club Name<Span>*</Span></label>
                      <input
                        type="text"
                        v-model="form.club_name"
                        name="fname"
                        placeholder="Club Name"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>First Name<Span>*</Span></label>
                      <input
                        type="text"
                        v-model="form.first_name"
                        name="fname"
                        placeholder="First Name"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Last Name<Span>*</Span></label>
                      <input
                        type="text"
                        v-model="form.last_name"
                        name="lname"
                        placeholder="Last Name"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Email<Span>*</Span></label>
                      <input
                        type="email"
                        v-model="form.mail"
                        name="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Phone Number<Span>*</Span></label>
                      <input
                        type="tel"
                        v-model="form.phone"
                        name="phone"
                        placeholder="Phone Number"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-sm-12">
                    <div class="field">
                      <label>Address<Span>*</Span></label>
                      <textarea
                        name="message"
                        v-model="form.addrs"
                        placeholder="Address"
                        rows="3"
                        cols="50"
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Country<Span>*</Span></label>
                      <select name="country" v-model="form.country">
                        <option>select Country</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>State<Span>*</Span></label>
                      <select name="state" v-model="form.state">
                        <option>State</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>City / Town <Span>*</Span></label>
                      <input type="text" name="city" v-model="form.city" placeholder="" required />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                      <label>Zip code <Span>*</Span></label>
                      <input type="text" v-model="form.zip" name="zip" placeholder="" required />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="field">
                    
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group field">
                      
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                       
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                     
                    </div>
                  </div>

                  <div class="col-sm-12">
                    <div class="field">
                     
                    </div>
                  </div>
                </div>
<!-- <div>{{user_data.first_name}}</div> -->
  <div ref="card" ></div>
  <button @click="purchase">Purchase</button>
 </div>

</template>

<script>
// import { getCustomer } from "@/api/registration";
 let style = {
  base: {
    border: '1px solid #D8D8D8',
    borderRadius: '4px',
    color: "#000",
  },
 }
let stripe =window.Stripe(`pk_test_51IwMJiEb3MV4Rb0896bfhMNDmDoM2BK7WuKpQgSykdFiXcbip7WVlmNUHWFVYaAKEwA9KnoYBr3KMb0RetHOI1Y0002rxCmN2f`),
    elements = stripe.elements(),
    card = undefined;

export default {
  data() {
    return {
      loading: false,
     
        form:{
         club_name:"",
         token:"",
        }
    };
  },
  // created() {
  //   this.fetchData(1);
  // },
 
  mounted: function () {
    
    card = elements.create('card',{hidePostalCode: true},style);
    card.mount(this.$refs.card);
  },
  methods:{

    // fetchData() {
    //   this.loading = true;
    //   getCustomer().then((response) => {
    //     this.user_data = response.data.data;
    //   console.log(response.data.data)
    //     this.loading = false;
    //   });
    // },
    // viewTeam(team_id) {
    //   this.$router.push({ path: "team-info/" + team_id });
    // },
   purchase () {
      //  alert();
      let me=this
    stripe.createToken(card).then(function(result) {

        console.log(card,'card');
      // Access the token with result.token
      // this.form.token=result.token;
      console.log(result.token);
      me.form.token=result.token;
    });
     console.log(this.form,'form data');
  }}
};
</script>