
import scoringguide from '../views/resources/ScoringGuide.vue'
import rankcalculator from '../views/resources/RankCalculator.vue'
import faq from '../views/resources/Faq.vue'


export default [
    {
      path: "/resource/scoring",
      name: "scoringguide",
      component:scoringguide,
      meta: { title: 'scoringguide'}
    },
    {
        path: "/resource/rankcalculator",
        name: "rankcalculator",
        component:rankcalculator,
        meta: { title: 'rankcalculator'}
      },
      {
        path: "/resource/faq",
        name: "faq",
        component:faq,
        meta: { title: 'faq'}
      },
    
]