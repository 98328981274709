<template>
<section class="wrapper padding-100">

        <div class="container">


            <div class="row justify-content-center">
				<div class="col-lg-12 col-md-12 col-12">
		<h1>Live Scoring Guide</h1>
		<div class="content">
			
	<iframe src='../pdf/sample.pdf'  ref="frame"></iframe>
	</div>
	</div>

</div>
</div>
</section>	
</template>