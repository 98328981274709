
import products from '../views/products/Products.vue'
import livescoring from '../views/products/Live_scoring.vue'


export default [
    {
      path: "/products/league",
      name: "products",
      component:products,
      meta: { title: 'products'}
    },
    {
      path: "/products/livescoring",
      name: "livescoring",
      component:livescoring,
      meta: { title: 'livescoring'}
    },
]