<template>
  <section class="app-bg">
    <div class="container">
      <div class="application-box">
        <div class="row">
          <div class="col-sm-8">
            <h2>IT’S CRICKET TIME! </h2>
            <p>
             Never stop until your good becomes better and your better becomes the best. Managing a professional match, a club or university match, a school match, or even a backyard game is as easy as pie. With the latest scores, highlights, press conferences, and more; you have access to everything you need on the go. Be a part of us now! 
            </p>
            <a href="#"
              ><img src="@/assets/images/apple-store.png" alt="" class="app-img"
            /></a>
            <a href="#"
              ><img src="@/assets/images/google-play.png" alt="" class="app-img"
            /></a>
          </div>

          <div class="col-sm-4">
            <img src="@/assets/images/app-img.png" alt="" class="application-box-img" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="footer-wrapper bg-wrapper-5">
    <div class="container">
      <div class="row">
        <div class="col-sm-4 col-6">
          <div class="footer">
            <h2>PRODUCTS</h2>
            <ul>
              <li><a href="" @click="league">League Management</a></li>
              <li><a href="" @click="scoring">Live Scoring App</a></li>
              <!-- <li><a href="">Live Stream</a></li>
              <li><a href="">Custom Apparel</a></li>
              <li><a href="">Scoreboard</a></li>
              <li><a href="">Academy Managment</a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-sm-4 col-6">
          <div class="footer">
            <h2>RESOURCES</h2>
            <ul>
              <li><a href="" @click="scoringguide">Scoring Guide</a></li>
              <li><a href="" @click="rank">Rank Calculator</a></li>
              <!-- <li><a href="" @click="">Videos</a></li> -->
              <li><a href="" @click="faq">FAQs</a></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-4 col-6">
          <div class="footer">
            <h2>COMPANY</h2>
            <ul>
              <li><a href="" @click="about">About</a></li>
              <!-- <li><a href="" @click="">Media</a></li> -->
              <li><a href="" @click="contact">Contact</a></li>
            </ul>
          </div>
        </div>

        <!-- <div class="col-sm-3 col-6">
          <div class="footer">
            <h2>MORE</h2>
            <ul>
              <li><a href="">Blogs</a></li>
              <li><a href="">Locate Clubs</a></li>
              <li><a href="">Live Videos</a></li>
              <li><a href="">Match Centre</a></li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </section>
  <section class="wrapper copy-wrapper">
    <div class="container-fluid">
      <div class="copy text-center">
        <p>Copyright © 2020 www.cricforever.com - All Rights Reserved.</p>
      </div>
    </div>
  </section>
  <a href="javascript:void(0)" class="scrollup">
    <font-awesome-icon icon="arrow-up" />
  </a>
</template>
<script>


    export default{
    name:"Footer",
    data(){
       
      // register:true

    },
    created(){
    
    },
    methods:{
  
      league(){
        this.$router.push({path:'/products/league'})
         

      },
      scoring(){
        this.$router.push({path:'/products/livescoring'})
        

      },
      scoringguide(){
        this.$router.push({path:'/resource/scoring'})
         

      },
      rank(){
        this.$router.push({path:'/resource/rankcalculator'})
         

      },
      faq(){
        this.$router.push({path:'/resource/faq'})
         

      },
      about(){
        this.$router.push({path:'/company/about'})
         

      },
contact(){
  this.$router.push({path:'/company/contact'})
         

},


     
    }
}
  
          
         
</script>

