import axios from "axios";
let BaseApi = axios.create({
    baseURL: "https://stagingurl.tk/msboys_scorecard_api/public/api/"
  });
  let Api = function() {
   
  
    return BaseApi;
  };
  
  export default Api;