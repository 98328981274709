<template>
 
    <section class="services-wrapper">
      <div class="container">
        <div class="white-box dark-bg">
          <div class="row">
            <div class="col-sm-6">
              <div class="content text-white">
                <h2 class="text-white">Products</h2>
                <p>
                 Smack the ultimate cricket experience with Cricforever. Our amazing products provide an inspiring cricket platform designed for cricket administrators, players, umpires and fans. 
                </p>
                <ul>
                  <li>
                    <i class="fas fa-tv text-white"></i> League Management
                  </li>
                  <li>
                    <i class="fas fa-mobile-alt text-white"></i> Live Scoring
                    App
                  </li>
                  <li>
                    <i class="fas fa-tshirt text-white"></i> Scoreboard 
                  </li>
                 
                </ul>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="content">
                <img
                  src="@/assets/images/slider-2.png"
                  alt=""
                  class="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="wrapper">
      <div class="container">
        <div class="row">
          <div class="col-sm-3 col-6">
            <div class="statics-box wow animated slideInUp">
              <div class="statics hvr-grow">
                <h2>57+</h2>
                <p>Countries</p>
              </div>
            </div>
          </div>
          <div class="col-sm-3 col-6">
            <div class="statics-box wow animated slideInUp">
              <div class="statics hvr-grow">
                <h2>16K+</h2>
                <p>Leagues</p>
              </div>
            </div>
          </div>
          <div class="col-sm-3 col-6">
            <div class="statics-box wow animated slideInUp">
              <div class="statics hvr-grow">
                <h2>18+</h2>
                <p>CF's</p>
              </div>
            </div>
          </div>
          <div class="col-sm-3 col-6">
            <div class="statics-box wow animated slideInUp">
              <div class="statics hvr-grow">
                <h2>3M+</h2>
                <p>Users</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="wrapper padding-100 light-bg">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="content mb-30">
              <h3><i class="fas fa-mobile"></i> Easy to customize</h3>
              <p>
               Cricforever comes with powerful customization options to meet your unique taste. It’s your way of Cricket. 
              </p>
            </div>
            <div class="content mb-30">
              <h3><i class="fas fa-cog"></i> Easy to Use</h3>
              <p>
                In a world where effortless on-the-go lifestyles are the norm, we create a user-centric experience that is simple, beautiful, and convenient. 
              </p>
            </div>
            <div class="content mb-30">
              <h3><i class="fas fa-download"></i> Get Started Today</h3>
              <p>
               It’s Cricforever for you forever. Take advantage of every opportunity that comes your way. Let’s get the ball rolling! 
              </p>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="content">
              <img src="@/assets/images/slider-1.png" class="img-responsive" />
            </div>
          </div>
          <!--end 1-->
        </div>
      </div>
    </section>
    <section class="wrapper padding-100 pricing-bg">
      <div class="container">
        <div class="row">
          <div class="content text-center">
            <h2>League Management Pricing</h2>
            <h5>Choose A Product and the plan</h5>
          </div>

          <div class="pricing-container">
            <div class="row">
              <div class="col-sm-4">
                <div class="pricing">
                  <div class="pricing-content">
                    <h3>Basic</h3>
                    <h2>$20</h2>
                    <p>Community</p>

                   <router-link class="red-br-btn hvr-ripple-out2" :to="{name:'pricing'}">Get Started</router-link> 
                  </div>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="pricing">
                  <div class="pricing-content">
                    <h3>Essentials</h3>
                    <h2>$30</h2>
                    <p>Community</p>

                   <router-link class="red-br-btn hvr-ripple-out2" :to="{name:'pricing'}">Get Started</router-link> 
                  </div>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="pricing">
                  <div class="pricing-content premium-box">
                    <h3>Premium</h3>
                    <h2>$40</h2>
                    <p>Community</p>
<router-link class="red-br-btn hvr-ripple-out2" :to="{name:'pricing'}">Get Started</router-link> 
                    <!-- <a href="" class="red-br-btn hvr-ripple-out2"
                      >Get Started</a
                    > -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="wrapper padding-100 light-bg">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="content">
              <h2>CricForever is used by</h2>
              <a href="" class="red-br-btn hvr-ripple-out2">Join Us</a>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-1.png" class="img-responsive" />
                </div>
              </div>

              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-2.png" class="img-responsive" />
                </div>
              </div>

              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-3.png" class="img-responsive" />
                </div>
              </div>

              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-4.png" class="img-responsive" />
                </div>
              </div>

              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-2.png" class="img-responsive" />
                </div>
              </div>

              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-1.png" class="img-responsive" />
                </div>
              </div>

              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-3.png" class="img-responsive" />
                </div>
              </div>

              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-4.png" class="img-responsive" />
                </div>
              </div>

              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-1.png" class="img-responsive" />
                </div>
              </div>

              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-2.png" class="img-responsive" />
                </div>
              </div>

              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-3.png" class="img-responsive" />
                </div>
              </div>

              <div class="col-sm-2 col-3">
                <div class="client-logo">
                  <img src="@/assets/images/img-2.png" class="img-responsive" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="wrapper padding-100 dark-bg">

        <div class="container">
            <div class="row justify-content-center">

                <div class="col-sm-12">

                    <div class="content text-center">
                        <div class="icon-box">
                            <h2>"</h2>
                        </div>
                        <h2 class="text-white f-70">A glimpse of our happy clients </h2>

                    </div>

                </div>

                <div class="col-sm-6">

                    <div class="textimonials">


<carousel :items-to-show="1" :autoplay="2500">
    <slide v-for="(datas,index) in data" :key="index">
     <p> {{datas}}</p>
    </slide>

    <template #addons>
      <!-- <navigation /> -->
      <pagination />
    </template>
  </carousel>
                    </div>
                </div>
            </div>

        </div>
    </section>
 
</template>
<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    Pagination,
    // Navigation,
  },
  data() {
    return{
    data: [
        '  “Cricforever is a complete package of league management that helps to understand more about the players and the tournament”. - Geogre Pratt ',
        '“Cricforever helped us manage leagues. The customized app allowed players to track live scores and fans to follow their favourite tournaments and teams”.',
        '“Thanks to Cricforever for this great platform for all our cricket activities. Highly recommended”.  ',
      ],
    }
  }
};
</script>