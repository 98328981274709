<template>
  <StripeElement :element="cardElement" @change="event = $event" />
  <button @click="registerCard">Add</button>
  <div v-if="event && event.error">{{ event.error.message }}</div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStripe, StripeElement } from 'vue-use-stripe'

export default defineComponent({
  components: { StripeElement },
  setup() {
    const event = ref(null)

    const {
      stripe,
      elements: [cardElement],
           } = useStripe({
      key: "pk_test_51JwhLHGKUrd8Z8whROYFvT7GDj96FQxzTfrgqmdus6Oc85ztqsYbv62WqLJmOIGUvyPIvRcGL0bajGlIAkPECPBP00UllblNSW",
      elements: [{ type: 'card', options: {hidePostalCode: true} }],
    })

    const registerCard = (response) => {
      console.log(response,'response');
     
       console.log(stripe.value,'value');
      if (event.value?.complete) {


        console.log(cardElement.value,'value');
        // Refer to the official docs to see all the Stripe instance properties.
        // E.g. https://stripe.com/docs/js/setup_intents/confirm_card_setup
        return stripe.value?.confirmCardSetup('{{setup_intent.client_secret}}', {
          payment_method: {
            card: cardElement.value,
          },
        }).then(function(result){
          console.log(result,'result');
        })
      }
    }

    return {
      event,
      cardElement,
      registerCard,
    }
  },
})
</script>
