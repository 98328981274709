import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@stripe/stripe-js'

// import '@/assets/css/all.css'
import '@/assets/css/bootstrap-grid.css'
import '@/assets/css/stylesheet.css'

import '@/assets/css/hover.css'
import '@/assets/css/animate.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import axios from 'axios';
import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

// import VueAxios from 'vue-axios'

// <script src="js/all.js"></script>
// <script src="js/fontawesome.js"></script>


// <script src="js/jquery-3.4.1.min.js"></script>
// <script src="js/smooth-scroll.js"></script>
// <script src="js/wow.js"></script>
// <script src="js/easeScroll.js"></script>




createApp(App).use(store).use(router,axios).component('font-awesome-icon', FontAwesomeIcon).component('loading',Loading).mount('#app')
