<template>
    <div>
         <loading :active='loading' :is-full-page="true" loader='bars' />
     <section class="wrapper padding-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="content">
                        <h3>Why choose us?</h3>
                        <p>We’re proud to deliver an incredible wholesome cricket platform for cricket fans, players, administrators in league management. Known for its intuitive interface, comprehensive league management, and user-friendliness, Cricforever is absolutely loved by our users.  </p>
                       
                        <h5 class="mb-3">Reach out to us to know more about our products. </h5>
                        
                        <a :href="base_url+'contact'" class="red-br-btn hvr-ripple-out2">Contact Us</a>
                    </div>
                </div>
                <div class="col-lg-6 align-self-enter">
                    <div class="content">
                        <img src="@/assets/images/slider-3.jpg" alt="" class="img-responsive">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="wrapper bg-light">
        <div class="container">
            <div class="row">
                <div class="col-sm-3 col-6">
                    <div class="statics-box">
                        <div class="statics hvr-grow">
                            <h2>57+</h2>
                            <p>Countries</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-6">
                    <div class="statics-box">
                        <div class="statics hvr-grow">
                            <h2>16K+</h2>
                            <p>Leagues</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-6">
                    <div class="statics-box">
                        <div class="statics hvr-grow">
                            <h2>18+</h2>
                            <p>CF's</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-6">
                    <div class="statics-box">
                        <div class="statics hvr-grow">
                            <h2>3M+</h2>
                            <p>Users</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>

<script>

export default{
    name:"About_us",
    data(){
      return {
          loading: false,
          base_url:'',
        
      }
},
    created () {
   this.base_url=process.env.BASE_URL;
   
      
    },
}
</script>
