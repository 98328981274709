<template>
    <div>
        <h3>Manage Your Subscription</h3>

        <label>Card</label>
        <div id="card-element">
243435
        </div>
    </div>
</template>
<script>
import stripe from 'stripe'
export default {
        data(){
            return {
                stripeAPIToken: 'pk_test_51JwhLHGKUrd8Z8whROYFvT7GDj96FQxzTfrgqmdus6Oc85ztqsYbv62WqLJmOIGUvyPIvRcGL0bajGlIAkPECPBP00UllblNSW',
    
                stripe: '',
                elements: '',
                card: ''
            }
        },
    
        mounted(){
            this.includeStripe('js.stripe.com/v3/', function(){
                this.configureStripe();
            }.bind(this) );
        },
    
        methods: {
            /*
                Includes Stripe.js dynamically
            */
            includeStripe( URL, callback ){
                let documentTag = document, tag = 'script',
                    object = documentTag.createElement(tag),
                    scriptTag = documentTag.getElementsByTagName(tag)[0];
                object.src = '//' + URL;
                if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                scriptTag.parentNode.insertBefore(object, scriptTag);
            },
    
            /*
                Configures Stripe by setting up the elements and 
                creating the card element.
            */
            configureStripe(){
                this.stripe = stripe( this.stripeAPIToken );
    
                this.elements = this.stripe.elements();
                this.card = this.elements.create('card');
    
                this.card.mount('#card-element');
            },
        }
    }
</script>
